import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field"];

  connect() {
    // This API is supported by the majority of browsers
    // https://caniuse.com/mdn-javascript_builtins_intl_datetimeformat_resolvedoptions_computed_timezone
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (this.fieldTarget.value.length == 0) {
      this.fieldTarget.value = timezone;
    }
  }
}
