import { Controller } from "@hotwired/stimulus";
import { Chart } from "chart.js/auto";
import { WordCloudChart } from "chartjs-chart-wordcloud";
import colors from "tailwindcss/colors";

export default class extends Controller {
  static targets = ["canvas"];
  static values = { label: String, words: Object, color: { type: String, default: "gray-500" } };

  connect() {
    const words = [];
    const frequency = [];
    Object.entries(this.wordsValue).forEach(([key, value]) => {
      words.push(key);
      frequency.push(value);
    });

    const ctx = this.canvasTarget.getContext("2d");
    const chart = new WordCloudChart(ctx, {
      data: {
        labels: words,
        datasets: [
          {
            label: this.labelValue,
            data: frequency,
            color: this.tailwindColor(),
          },
        ],
      },
      options: {
        title: { display: false },
        plugins: {
          legend: { display: false },
          title: {
            display: true,
          },
        },
      },
    });
  }

  tailwindColor() {
    const [colorName, shade] = this.colorValue.split("-").slice(-2);
    const colorHex = colors[colorName]?.[shade];

    const r = parseInt(colorHex.slice(1, 3), 16);
    const g = parseInt(colorHex.slice(3, 5), 16);
    const b = parseInt(colorHex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, 1)`;
  }
}
