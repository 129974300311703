import { Dropdown } from "tailwindcss-stimulus-components";

export default class extends Dropdown {
  static targets = ["svg"];
  static classes = ["show", "hide"];

  connect() {
    super.connect();
    this.handle_chevron();
  }

  disconnect() {
    super.disconnect();
  }

  toggle() {
    super.toggle();
    this.handle_chevron();
  }

  handle_chevron() {
    if (this.openValue) {
      this.svgTarget.classList.remove(...this.hideClasses);
      this.svgTarget.classList.add(...this.showClasses);
    } else {
      this.svgTarget.classList.add(...this.hideClasses);
      this.svgTarget.classList.remove(...this.showClasses);
    }
  }
}
