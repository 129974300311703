import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "option", "selected", "dropdown"];
  static values = { active: Array };

  connect() {
    this.updateInputValue();
  }

  toggleDropdown() {
    this.dropdownTarget.classList.toggle("hidden");
  }

  closeDropdown() {
    this.dropdownTarget.classList.add("hidden");
  }

  toggleOption(event) {
    event.preventDefault();
    const option = event.currentTarget;
    const id = option.dataset.id;

    if (option.classList.contains("selected")) {
      this.deselectOption(option, id);
    } else {
      this.selectOption(option, id);
    }

    this.updateInputValue();
    this.dropdownTarget.classList.add("hidden");
  }

  selectOption(option, id) {
    option.classList.add(...this.activeValue);

    const hiddenInput = option.querySelector(`[data-id="${id}"]`);
    hiddenInput.removeAttribute("disabled");
    option.setAttribute("data-multiselect-target", "selected");
  }

  deselectOption(option, id) {
    option.classList.remove(...this.activeValue);
    const hiddenInput = option.querySelector(`[data-id="${id}"]`);
    hiddenInput.setAttribute("disabled", "true");
    option.setAttribute("data-multiselect-target", "option");
  }

  updateInputValue() {
    const selectedNames = Array.from(this.selectedTargets).map((target) =>
      target.closest('[role="option"]').textContent.trim()
    );
    this.inputTarget.textContent = selectedNames.join(", ");
  }
}
