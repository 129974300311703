import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const consentGivenAt = localStorage.getItem("cookie-consent-given-at")
    if (!consentGivenAt) {
      this.element.classList.remove("hidden")
    }
  }

  accept() {
    const consentTimestamp = new Date().toISOString()
    localStorage.setItem("cookie-consent-given-at", consentTimestamp)

    this.element.classList.add("hidden")
  }
}
