import { Controller } from "@hotwired/stimulus";

/*
  <div class="sm:col-span-2">
    <div class="flex items-start"
         data-controller="switch"
         data-switch-button-enabled-class="bg-indigo-600"
         data-switch-button-disabled-class="bg-gray-200"
         data-switch-span-enabled-class="translate-x-5"
         data-switch-span-disabled-class="translate-x-0">
      <div class="flex-shrink-0" data-action='click->switch#toggle touch->switch#toggle'>
        <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
        <button data-switch-target="button"
                class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                type="button" role="switch" aria-checked="false">
          <span class="sr-only">Agree to policies</span>
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span data-switch-target="span"
                aria-hidden="true" class="translate-x-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out">
          </span>
        </button>
      </div>
      <div class="ml-3">
        <p class="text-base text-gray-500">
        By selecting this, you agree to the
        <a href="#" class="font-medium text-gray-700 underline">Privacy Policy</a>
        and
        <a href="#" class="font-medium text-gray-700 underline">Cookie Policy</a>.
        </p>
      </div>
    </div>
  </div>
*/

export default class extends Controller {
  static targets = ["button", "span", "field"];
  static classes = ["buttonEnabled", "buttonDisabled", "spanEnabled", "spanDisabled"];

  connect() {
    if (this.enabled()) {
      this.switchOn();
    } else {
      this.switchOff();
    }
  }

  toggle() {
    if (event.target.getAttribute("type") != "checkbox") {
      event.preventDefault();
    }
    if (this.enabled()) {
      this.switchOff();
    } else {
      this.switchOn();
    }
  }

  switchOn() {
    this.buttonTarget.classList.remove(this.buttonDisabledClass);
    this.buttonTarget.classList.add(this.buttonEnabledClass);
    this.buttonTarget.setAttribute("aria-checked", "true");
    this.spanTarget.setAttribute("aria-checked", "true");
    this.spanTarget.classList.remove(this.spanDisabledClass);
    this.spanTarget.classList.add(this.spanEnabledClass);
    this.fieldTarget.setAttribute("value", "true");
  }

  switchOff() {
    this.buttonTarget.classList.add(this.buttonDisabledClass);
    this.buttonTarget.classList.remove(this.buttonEnabledClass);
    this.buttonTarget.setAttribute("aria-checked", "false");
    this.spanTarget.setAttribute("aria-checked", "false");
    this.spanTarget.classList.add(this.spanDisabledClass);
    this.spanTarget.classList.remove(this.spanEnabledClass);
    this.fieldTarget.setAttribute("value", "false");
  }

  enabled() {
    return this.fieldTarget.getAttribute("value") == "true";
  }
}
